<template>
  <v-layout row>
    <v-flex xs12>
      <v-alert type="success">
          엑셀견적 완료시 까지 해당 진행내역을 기록해 놨습니다<br />
          어디까지 진행되는지 어느 과정에 있는지 그 내역을 볼 수 있습니다<br />
          리스트에서 확장아이콘을 클릭하면 내용이 보입니다<br />
        </v-alert>
      <v-data-table
        sort-by
        v-model="data.selected"
        :headers="data.headers"
        :items="data.items"
        :items-per-page="5"
        :search="data.search"
        class="elevation-1"
        item-key="id"
        show-select        
        disable-pagination
        hide-default-footer
        show-expand
        no-data-text="데이터가 없습니다"
        no-results-text="`검색결과가 없습니다`"
        >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              flat
              solo
              prepend-icon="mdi-magnify"
              placeholder="결과내검색"
              v-model="data.search"
              hide-details
            
              ></v-text-field>
            <v-spacer></v-spacer>
            <v-subheader>현재:{{data.items.length}}</v-subheader>

            <v-tooltip bottom >
              <template v-slot:activator="{ on }">
                <v-btn v-if="$store.getters.user.email==='ingsoft@hanmail.net'" color="success" v-on="on"  @click="onAdd" >
                  <v-icon >mdi-plus</v-icon>
                  등록
                </v-btn>
              </template>              
              <span >진행사항을 등록합니다</span>
            </v-tooltip>
            <v-tooltip bottom v-if="data.selected.length>0">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on"  @click="onRemoveChecked">
                <v-badge 
                  class="align-self-center" 
                  color="red"
                  >
                  <template v-slot:badge>
                    <span>{{data.selected.length}}</span>
                  </template>
                  <v-icon v-on="on">mdi-delete</v-icon>
                </v-badge>
                </v-btn>
              </template>
              <span v-if="data.selected.length>0">{{data.selected.length}}개의 데이터를 삭제합니다</span>
              <span v-else>삭제할 데이터를 선택하세요</span>
            </v-tooltip>
            <v-tooltip bottom >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" ><v-icon v-on="on" @click="onDataRefresh">mdi-refresh</v-icon></v-btn>
              </template>
              <span >새로고침</span>
            </v-tooltip>

          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                
                <v-card-text >
                  <div v-html="item.contents"></div>
                </v-card-text>
                
            </td>
        </template>
      </v-data-table>
      <v-btn block  text v-on:click="onLoadMore" v-if="!this.paging.end">{{paging.pageSize}}개 더보기</v-btn>       
    </v-flex>
  </v-layout>

</template>

<script>

export default {
  components: {
   // Confirm
  },
  data () {
    return {
      query: {
        firebase: this.$store.getters.firebase,
        ref: this.$store.getters.ref,
        thisRef: '',
        user: this.$store.getters.user,
        queryFirst:'',
        queryNext:'',
        refDB:''
      },
      data: {
        
        search: '',
        selected: [],
        items: [],
        totalCount: 0,
        nextData: false,
        headers: [
          {text: '제목', value: 'subject'},
          {text: '저장일자', value: 'createdAt'},


        ],
      },
      paging: {
          pageSize: 500, 
          end: false,
          loading: false
      },

      
      dialog:false,
      confireMsgHeader: {
        title: '계정삭제',
        buttonTrueText: '삭제하기',
        buttonFalseText: '취소'
      },
       
      formOptions: {
        isLoading: false,
        siteName: '',
        show1: false
      },
      formsMax: {
        maxId: 1,
        endday: '',
      },
      table: 'timeline'
      
     
    };
  },
  created () {
    
    this.query.refDB = this.query.firebase.firestore().collection(this.table)
    this.$log.warn(this.table,this.$store.getters.user.uid)   
        

    this.query.queryFirst =  this.query.firebase.firestore().collection(this.table)
    
    this.onDataRefresh()
    // this.getData()
  
  },
  mounted () {
   

    //
  },
  methods: {
    onAdd() {
      this.$router.push({ name: 'user.timeline.add'})
    },
    onAccountState(accountState) {
      if (accountState===1) {
        return '일시블럭'
      } else if (accountState===2) {
        return '영구블럭'
      } else if (accountState===3) {
        return '비번틀림'
      } else if (accountState===4) {
        return '그외오류'
      } else {
        return '정상'
      }
    },
    onAccountLevel(accountLevel) {      
      if (accountLevel===1) {
        return '딜러'
      } else {
        return '개인'
      }
    },
    onLoadMore () {
      this.$log.warn('onloadmore',this.paging)
      if (this.paging.end) {
        return
      }        
        this.paging.loading = true;
        this.handleQuestions(this.query.queryNext).then((documentSnapshots) => {
            this.paging.loading = false;            
            if (documentSnapshots.empty) {
              this.paging.end = true;
            }
        })
    },
    onDataRefresh() {      
      this.data.items=[]
      const firstPage = this.query.queryFirst.limit(this.paging.pageSize)     
      this.handleQuestions(firstPage);
    },
    handleEdit (item) {
     
      this.$router.push({ name: 'user.request.edit', params: { id: item.id }})
    
    },
    handleQuestions (ref) {
      const _this = this
      return new Promise((resolve, reject) => {
        ref.get().then((documentSnapshots) => {
          if (documentSnapshots.empty) {
            this.paging.end = true
            resolve(documentSnapshots)
            _this.$log.warn(reject)
          }
          documentSnapshots.forEach((doc) => {
            const theitem = doc.data()
            theitem.id = doc.id
            
            _this.data.items.push(theitem)
          })
          _this.$log.warn('data',_this.data.items)
          _this.data.nextData = true
          const lastVisible = documentSnapshots.docs[documentSnapshots.size - 1]
          if (_this.data.items.length<this.paging.pageSize) {
            _this.paging.end = true
          }
          if (!lastVisible) {
            _this.paging.end = true
            return
          }
          this.query.queryNext = this.query.queryFirst
            .startAfter(lastVisible)
            .limit(this.paging.pageSize)
            resolve(documentSnapshots)
          })
        })
    },
    gteMaxId () {
      

       
       this.query.firebase.database().ref(this.query.ref.license + '/' + this.$store.getters.user.uid).child(this.$store.getters.user.uid)
        .once('value', snapshot => {
          let value = snapshot.val()
         
          if (value !== null) {
            this.formsMax.endday = value.endday
            this.formsMax.maxId = value.maxId
            //this.forms.updated = value.updated
          }
        })
        
    },
    onGetAccountState(item) {
      let rv = ""
      switch(item) {
        case 1:
          rv = "일시블럭"
          break;
        case 2:
          rv = "영구블럭"
          break;
        case 3:
          rv = "비번틀림"
          break;
        case 4:
          rv = "그외오류"
          break;
        default:
          // code block
      }
      return rv

      
    },
    onReserveRemove(params) {
      if (params.result === 'false' && params.message !=='') {
        this.$toasted.show(params.message, {type: 'error'})
      } else  if (params.result === 'true')  {
        if ( params.message !=='') {
          this.$toasted.show(params.message, {type: 'success'})
        } else {
          this.$toasted.show('정상적으로 삭제되었습니다', {type: 'success'})
        }
      
        this.data.items = params.data

      }
      this.data.totalCount = parseInt(params.totalCount)
      
    },
    getData () {
      

      this.query.firebase.database().ref(this.query.ref.mysite + '/' + this.$store.getters.user.uid)
        .limitToLast(this.query.currentPage * this.query.pageSize)
        .on('value', snapshot => {
          const items = []
      
          snapshot.forEach((doc) => {
            const theitem = doc.val()
            theitem.id = doc.key
            items.push(theitem)
          })
          items.reverse()
          this.data.items = items
          this.query.currentPage = this.query.currentPage + 1
          if (this.data.items.length === this.data.totalCount) {
            this.query.nextData = false
          }
          this.data.totalCount = this.data.items.length
          if (this.query.pageSize > this.data.totalCount) {
            this.data.nextData = false
          }
        })

    
    },
    getSiteInfo () {
      //this.forms.siteName=''
      //for (let item of this.siteInfo.items) {
      
      //  if (item.siteNameEn === this.forms.siteNameEn) {
      //    this.forms.siteName = item.siteName
      //    break
      //  }
        
       
     // }
    },
    
    onRemoveChecked () {
      let _this = this
      
      this.$confirm('선택한 '+  _this.data.selected.length + '개의 데이터를 삭제하시겠습니까?', this.confireMsgHeader).then(res => {
        if (res) {
          //window.getApp.$emit('APP_OVERLAY',{show:true,text:'삭제중입니다...'})
          const userRef = this.query.refDB
          //let deletes = {}
          this.$log.warn('delete',_this.data.selected)
          _this.data.selected.map(function (value) {
            let index = _this.data.items.findIndex(x => x.id === value)
            _this.data.items.splice(index, 1)
            //_this.$log.warn('value.id',value.id)
            userRef.doc(value.id).delete()
          }) 
          _this.data.selected = []
          //window.getApp.$emit('APP_OVERLAY',{show:false,text:''})
          this.$toasted.show('정상적으로 삭제되었습니다', {type: 'success'})


          //let deletes = {}
         
          //_this.data.selected.map(function (value) {
          //  deletes[value.id] = null
          //})
          //this.onRemove(_this, deletes)
        }
      })
    },

    onRemove (_this, deletes) {
      // 실제 데이터 삭제하기
      // let loader = this.$loading.show()
      _this.query.firebase.database().ref(this.query.ref.mysite + '/' +  this.$store.getters.user.uid).update(deletes)
      _this.data.selected = []
     
      _this.data.selected = []
      this.$toasted.show('정상적으로 삭제되었습니다', {type: 'success'})
      // loader.hide()
    },
    
    handleDelete (item) {
      let _this = this
     
      
      this.$confirm(`${item.siteName}-${item.siteId}를 삭제하시겠습니까?`, this.confireMsgHeader).then(res => {
        if (res) {
          let deletes = {}
          deletes[item.id] = null
          this.onRemove(_this, deletes)
         
        }
      })
      
    },
    
  }
};
</script>
